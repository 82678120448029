
//Header//
#header {
    background: 0 0;
    height: inherit;
    padding: 0;
    display: block;
    position: relative;
    &::before {
        display: none;
    }
    .header-main-container {
        background: #fff;
    }
    .content {
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}