//Custom variables

// Descriptive color variables

// Functional color variables
$cl-silver-cultured: #f7f7f7;
$cl-cinza2: #e0e0e0;
$cl-cinza3: #2b2b2b;
$cl-cinza4: #adadad;
$cl-cinza5: #e6e7e8;
$cl-texto: #4f4f4f;
$cl-rosa2: #d11b3a;
$cl-rosa3: #b81832;
$cl-amarelo: #f69831;
$cl-roxo: #782861;
$cl-preto: #000;
$cl-branco: #fff;
$cl-vermelho: #b81832;

$cl-europa: #eb1f40;
$cl-judaica: #f69831;
$cl-orientemedio: #782861;
$cl-africa: #6fb529;
$cl-lesteasiatico: #b8336a;
$cl-americas: #ff4d80;
$cl-outros: #002642;

// Font stacks
$ff-open-sans: 'Open Sans', sans-serif;
$ff-roboto: 'Roboto', sans-serif;
$ff-cabin: 'Cabin';
$ff-mulish: 'Mulish', sans-serif;

$fw-book: 100;
$fw-regular: 300;
$fw-medium: 400;
$fw-semibold: 600;
$fw-bold: 700;

// Asset paths
$path-image:  '../img';
$path-fonts:  '../fonts';

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cabin:400,500&display=swap');



@font-face {
    font-family: 'Font Awesome 5 Brands';
    font-style: normal;
    font-weight: normal;
    font-display: auto;
    src: url('#{$path-fonts}/fontawesome-free/fa-brands-400.eot');
    src: url('#{$path-fonts}/fontawesome-free/fa-brands-400.eot?#iefix') format("embedded-opentype"),
    url('#{$path-fonts}/fontawesome-free/fa-brands-400.woff2') format("woff2"),
    url('#{$path-fonts}/fontawesome-free/fa-brands-400.woff') format("woff"),
    url('#{$path-fonts}/fontawesome-free/fa-brands-400.ttf') format("truetype"),
    url('#{$path-fonts}/fontawesome-free/fa-brands-400.svg#fontawesome') format("svg");
}
@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url('#{$path-fonts}/fontawesome-free/fa-regular-400.eot');
    src: url('#{$path-fonts}/fontawesome-free/fa-regular-400.eot?#iefix') format("embedded-opentype"),
    url('#{$path-fonts}/fontawesome-free/fa-regular-400.woff2') format("woff2"),
    url('#{$path-fonts}/fontawesome-free/fa-regular-400.woff') format("woff"),
    url('#{$path-fonts}/fontawesome-free/fa-regular-400.ttf') format("truetype"),
    url('#{$path-fonts}/fontawesome-free/fa-regular-400.svg#fontawesome') format("svg");
}
@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url('#{$path-fonts}/fontawesome-free/fa-solid-900.eot');
    src: url('#{$path-fonts}/fontawesome-free/fa-solid-900.eot?#iefix') format("embedded-opentype"),
    url('#{$path-fonts}/fontawesome-free/fa-solid-900.woff2') format("woff2"),
    url('#{$path-fonts}/fontawesome-free/fa-solid-900.woff') format("woff"),
    url('#{$path-fonts}/fontawesome-free/fa-solid-900.ttf') format("truetype"),
    url('#{$path-fonts}/fontawesome-free/fa-solid-900.svg#fontawesome') format("svg");
}
