

//Body background 
////Register Background
#register {
    background: #000 url('#{$path-image}/new-register/bg-new-register.png') no-repeat;
    background-position: top;
    background-size: contain;
}


//Content
.content-white-box {
    width: 90%;
    max-width: 416px;
    margin: 24px auto 56px;
}

//new label
.image-new-label {
    position: relative;
    width: 100%;
    height: 133px;
    margin-bottom: 16px;

    .loader {
        opacity: 0;
        &.white {
            background: rgba(255,255,255,.8);
            position: absolute;
        }
        &.show {
            opacity: 1;
        }
    }
    .center-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: 16px 0 -16px;
    }
    .new-label {
        background:url('#{$path-image}/new-register/new-label.svg') no-repeat;
        background-size: contain;        
    }
    .new-label-one {
        background:url('#{$path-image}/new-register/new-label-red-one.svg') no-repeat;
        background-size: contain;        
    }
    .new-label-two {
        background:url('#{$path-image}/new-register/new-label-red-two.svg') no-repeat;
        background-size: contain;        
    }
    .old-label {
        background:url('#{$path-image}/new-register/old-label.svg') no-repeat;
        background-size: contain;        
    }
    .old-label-one {
        background:url('#{$path-image}/new-register/old-label-red-one.svg') no-repeat;
        background-size: contain;        
    }
    .old-label-two {
        background:url('#{$path-image}/new-register/old-label-red-two.svg') no-repeat;
        background-size: contain;        
    }
    //latam

    .new-label-latam {
        background:url('#{$path-image}/new-register/new-label-latam.svg') no-repeat;
        background-size: contain;        
    }
    .new-label-one-latam {
        background:url('#{$path-image}/new-register/new-label-red-one-latam.svg') no-repeat;
        background-size: contain;        
    }
    .new-label-two-latam {
        background:url('#{$path-image}/new-register/new-label-red-two-latam.svg') no-repeat;
        background-size: contain;        
    }

    .old-label-latam {
        background:url('#{$path-image}/new-register/old-label-latam.svg') no-repeat;
        background-size: contain;        
    }
    .old-label-one-latam {
        background:url('#{$path-image}/new-register/old-label-latam-red-one.svg') no-repeat;
        background-size: contain;        
    }
    .old-label-two-latam {
        background:url('#{$path-image}/new-register/old-label-latam-red-two.svg') no-repeat;
        background-size: contain;        
    }
}

.gen-card-body.text-center.labels {
    padding: 0;
    margin: 4px auto 16px;
    
    button {
        margin: 0;
    }
}
